import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import Player from "./Player";
import InRlPlayer from "./InRlPlayer";
function App() {

  const [searchVideo, setSearchVideo] = useState('');
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(30);
  const [usedForYoutube, setUsedForYoutube] = useState(true);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    let youtubeVideo = new URLSearchParams(window.location.search).get("video");
    let inrlVideo = new URLSearchParams(window.location.search).get("inrl");
    let IsParamMuted = new URLSearchParams(window.location.search).get("isMuted");
    var video = "";
    console.log("IsParamMuted ", IsParamMuted)
    if (youtubeVideo) {
      video = youtubeVideo;
      setUsedForYoutube(true)
    }
    if (inrlVideo) {
      video = inrlVideo;
      setUsedForYoutube(false)
    }
    if (IsParamMuted) {
      setIsMuted((IsParamMuted.toLowerCase() === "true"))
    }
    setSearchVideo(video);
    let _startTime = new URLSearchParams(window.location.search).get("start");
    let _endTime = new URLSearchParams(window.location.search).get("end");
    console.log("video url ", video, _startTime, _endTime)
    if (_startTime) {
      setStartTime(_startTime);
    }
    if (_endTime) {
      setEndTime(_endTime);
    }
  }, []);



  return (
    <div>

      {usedForYoutube == true ? <Player
        searchVideo={searchVideo}
        startTime={startTime}
        endTime={endTime}
      /> : <InRlPlayer
        searchVideo={searchVideo}
        isMuted={isMuted}
      />}
    </div>

  );
}

export default App;
