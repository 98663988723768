import React, { useState } from "react";
import ReactPlayer from "react-player";
function InRlPlayer({ searchVideo, isMuted }) {
    const [isPlayerReady, setIsPlayerReady] = useState(false);
    var videoPlayer;

    console.log("start end time ", searchVideo, isMuted)

    function onReadyCalled() {
        if (isPlayerReady == false) {

            if (isMuted == false) {
                console.log("Ready Called Unity");
            }



            var videoElement = videoPlayer.getInternalPlayer();
            console.log("videoHeight", videoPlayer.getInternalPlayer());
            const resoData = {
                width: videoElement.videoWidth,
                height: videoElement.videoHeight
            }
            var videoDataJson = JSON.stringify(resoData);
            console.log("videoUnityResolution", videoDataJson);
            setIsPlayerReady(true)
        }

    }

    return (
        <div className='player-wrapper'>
            <ReactPlayer
                ref={player => { videoPlayer = player }}
                url={searchVideo}//'https://www.youtube.com/watch?v=UVCP4bKy9Iw&ab_channel=ChristopheDevred'
                className='react-player'
                playing
                muted={isMuted}
                width='100%'
                height='100%'
                controls={true}
                loop={true}
                onReady={onReadyCalled}
            // onPlay={onReadyCalled}
            />
        </div>
    )
}
export default InRlPlayer;