import React, { useState } from "react";
import ReactPlayer from "react-player";
function Player({ searchVideo, startTime, endTime }) {
    var videoPlayer;
    var _startTime = startTime
    var _endTime = endTime
    console.log("start end time ", _startTime, _endTime)
    function onReadyCalled() {
        console.log("Ready Called Unity");
    }
    return (
        <div className='player-wrapper'>
            <ReactPlayer
                ref={player => { videoPlayer = player }}
                url={searchVideo}//'https://www.youtube.com/watch?v=UVCP4bKy9Iw&ab_channel=ChristopheDevred'
                className='react-player'
                playing
                muted={false}
                width='100%'
                //height='100%'
                progressInterval={100}
                onProgress={progress => {
                    if (_endTime != -1) {
                        if (progress.playedSeconds >= _endTime) {
                            console.log('process', progress.playedSeconds)
                            videoPlayer.seekTo(0)
                        }
                    }
                    if (progress.played >= 0.85) {
                        videoPlayer.seekTo(0)
                    }
                }}
                onReady={onReadyCalled}
            />
        </div>
    )
}
export default Player;